.devices {
  font-family: "Poppins", sans-serif;

  .web {
    .appbar {
      background-color: white;
      overflow: hidden;

      .toolbar {
        position: relative;
        margin: auto;
        height: 100px;
        width: 80vw;

        .logo {
          height: 50px;
          margin-right: 0.5rem;
          vertical-align: middle;
          cursor: pointer;
        }

        .first {
          margin-left: auto;
          vertical-align: middle;
          cursor: pointer;
          color: black;
          opacity: 0.7;
          text-decoration: none;

          .btn {
            font-family: "Poppins", sans-serif;
            color: black;
            text-transform: none;
          }

          &:hover {
            text-decoration: underline;
            opacity: 1;
          }
        }

        .selected {
          margin-left: 20px;
          vertical-align: middle;
          cursor: pointer;
          color: black;

          .btn {
            font-family: "Poppins", sans-serif;
            color: black;
            text-transform: none;
          }
        }

        .link {
          vertical-align: middle;
          cursor: pointer;
          color: black;
          opacity: 0.7;
          text-decoration: none;
          margin-left: 20px;

          .btn {
            font-family: "Poppins", sans-serif;
            color: black;
            text-transform: none;
          }

          &:hover {
            text-decoration: underline;
            opacity: 1;
          }
        }
      }
    }

    .content {
      height: calc(100vh - 170px);
      width: 100vw;
      margin: 10px;

      padding-top: 30px;

      position: relative;
      margin: auto;
      overflow-x: hidden;

      .text {
        margin-left: 100px;
        margin-right: 100px;
        box-shadow: none;
        margin-bottom: 20px;
      }

      .item {
        height: 320px;
        margin-left: 100px;
        margin-right: 100px;
        
        box-shadow: none;

        .left-item {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .prod-img {
            width: 300px;
            margin-left: 40px;
            margin-top: 10px;
            margin-bottom: 10px;
          }

          .prod-text {
            font-weight: 600;
            font-size: 1.1rem;
          }
        }
      }

      .dropdown {
        margin-left: 100px;
        margin-right: 100px;
        margin-top: 50px;

        .dd-head {
          background-color: rgb(241, 239, 239);
          color: black;
          font-family: "Poppins", sans-serif;
          font-size: 1rem;
          font-weight: 600;
          border-radius: 10px;
          margin-top: 20px;
          margin-bottom: 20px;

          display: flex;
          justify-content: space-between;
          align-items: center;

          .dd-btn {
            color: black;
          }
        }

        .dd-body {
          color: black;
          font-family: "Poppins", sans-serif;
          font-size: 1rem;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }

    .footer {
      height: 40px;
      background-color: rgb(241, 239, 239);

      padding-left: 50px;
      padding-right: 50px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .copyright {
        font-size: 0.8rem;
        font-weight: 500;
      }

      .options {
        display: flex;

        .link-cover {
          text-decoration: none;
          color: black;
        }

        .option-item {
          font-size: 0.8rem;
          font-weight: 500;
          margin-left: 12px;
          cursor: pointer;
        }
      }
    }
  }

  .mobile {
    display: none;
  }

  @media (max-width: 900px) {
    .web {
      display: none;
    }

    .mobile {
      display: block;

      .appbar {
        background-color: white;

        .toolbar {
          position: relative;
          margin: auto;
          height: 100px;
          width: 80vw;

          .logo {
            height: 50px;
            margin-right: 0.5rem;
            vertical-align: middle;
            cursor: pointer;
          }

          .menu-icon {
            margin-left: auto;
            color: black;
            opacity: 0.75;
          }
        }
      }

      .content {
        height: calc(100vh - 140px);
        width: 100vw;
        margin: 10px;

        position: relative;
        margin: auto;
        overflow-x: hidden;
        text-align: center;

        .title {
          font-weight: 700;
          font-size: 1.2rem;
          margin-bottom: 20px;
        }

        .item {
          .prod-img {
            width: 80%;
          }

          .title {
            font-weight: 500;
            font-size: 1.1rem;
          }

          .content-text {
            font-size: 0.9rem;
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 20px;
          }
        }

        .dropdown {
          margin-left: 20px;
          margin-right: 20px;

          .dd-head {
            background-color: rgb(241, 239, 239);
            color: black;
            font-family: "Poppins", sans-serif;
            font-size: 0.9rem;
            border-radius: 10px;
            margin-top: 20px;
            margin-bottom: 20px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            .dd-btn {
              color: black;
            }
          }

          .dd-body {
            color: black;
            font-family: "Poppins", sans-serif;
            font-size: 0.9rem;
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: left;
          }
        }
      }

      .footer {
        height: 40px;
        background-color: rgb(241, 239, 239);

        padding-left: 50px;
        padding-right: 50px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .copyright {
          font-size: 0.75rem;
          font-weight: 500;
          text-align: center;
        }
      }
    }
  }
}
